import './bootstrap';
import jQuery from 'jquery';
window.$ = jQuery;

import livewire from '@defstudio/vite-livewire-plugin';

import Alpine from 'alpinejs';
import mask from '@alpinejs/mask';				// https://alpinejs.dev/plugins/mask
import intersect from '@alpinejs/intersect';	// https://alpinejs.dev/plugins/intersect
import persist from '@alpinejs/persist';		// https://alpinejs.dev/plugins/persist
import focus from '@alpinejs/focus';			// https://alpinejs.dev/plugins/focus
import collapse from '@alpinejs/collapse';		// https://alpinejs.dev/plugins/collapse
import morph from '@alpinejs/morph';			// https://alpinejs.dev/plugins/morph

Alpine.plugin(mask)
Alpine.plugin(intersect)
Alpine.plugin(persist) 
Alpine.plugin(focus) 
Alpine.plugin(collapse)

window.Alpine = Alpine
Alpine.plugin(morph)

Alpine.start();

$(document).ready(function() {
    $(window).scroll((function() {
        if( $(window).scrollTop() > 0 ) {
            $("#topNav").addClass("bg-white shadow-lg").removeClass("bg-transparent");
            $("#navigation").removeClass("mt-4");
        } else {
            $("#topNav").addClass("bg-transparent").removeClass("bg-white shadow-lg");
            $("#navigation").addClass("mt-4");
        }
    }));

    $(".nav-toggler").each(function(_, navToggler) {
        var target = $(navToggler).data("target");
        $(navToggler).on("click", function() {
            $(target).animate({
                height: "toggle"
            });
        });
    });

    $("#navigation a").on("click", function() {
        if ($(window).width() < 768 ) {
            $("#navigation").animate({
                height: "toggle"
            });
        }                    
    });

    // Add smooth scrolling to all hashed links
    $('a[href^="#"]').on('click',function (e) {
        e.preventDefault();

        var target = this.hash;
        var $target = $(target);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top - 40
        }, 900, 'swing');
    });

    // Add smooth scrolling to all scrollToTop class
    $(document).on( 'click' , '.scrollToTop' , function (e) {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });
});